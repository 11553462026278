import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import useApi from '../services/api';
import { useAppContext } from '../context/AppContext';

const StyledTenantSwitcher = styled.div`
  left: 10px;
  right: 10px;
  bottom: 10px;
  width: calc(100% - 20px);
  height: 30px;
  background-color: #F9FAFB;
  color: #333333;
  border: 1px solid #D1D5DB;
  border-radius: 4px;
  padding: 0;
  position: absolute;

  select {
    padding: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    background-color: #FFFFFF;
    color: #4B5563;
    border: none;
    outline: none;
    font-size: 1rem;
  }
`;


const TenantSwitcher = () => {
  const { getToken, getClaim, getOrganization, login, isAuthenticated } = useKindeAuth();
  const { token, setToken, tenant, setTenant } = useAppContext();
  const [selectedOrgCode, setSelectedOrgCode] = useState("");
  const { getTenantInfo } = useApi();
  const prevOrgIdRef = useRef(null);

useEffect(() => {
  console.log("Nuevo token:", token);
  const getTokenAndTenantInfo = async () => {
    if (token && token.accessToken) {
      const tenantInfo = await getTenantInfo();
      await setTenant(tenantInfo);
    }
  };

  getTokenAndTenantInfo().catch(console.error);

}, [token]); // Ahora solo el token es una dependencia

useEffect(() => {
  const getTokenAndSetToken = async () => {
    try {
      const currentOrg = await getOrganization(); 
      const accessToken = await getToken();
      const tokenToUpdate = {
          accessToken: accessToken,
          orgId: currentOrg.orgCode,
        };
       setToken(tokenToUpdate);
      
    } catch (error) {
      console.error("Error getting access token:", error);
    }
  };

  if(isAuthenticated)getTokenAndSetToken()
}, [isAuthenticated]);

  const handleSelectChange = async (event) => {
    const selectedValue = event.target.value;
    setSelectedOrgCode(selectedValue);
    prevOrgIdRef.current = getOrganization().orgCode;
    login({ org_code: selectedValue });
    
  };

  return (
    <StyledTenantSwitcher>
      {isAuthenticated && (
        <select
          value={selectedOrgCode}
          onChange={handleSelectChange}
        >
        {selectedOrgCode === "" && (
        <option value="" disabled hidden>
          {tenant !== null ? tenant.tenantName : "Choose a Tenant"}
        </option>
      )}
          {getClaim("organizations", "id_token").value.map((item) => {
            if (getOrganization().orgCode !== item.id) {
              return (
                <option
                  key={item.id}
                  value={item.id}
                >
                  {item.name}
                </option>
              );
            }
            return null; 
          })}
        </select>
      )}
    </StyledTenantSwitcher>
  );
};

export default TenantSwitcher;