import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FiEdit } from 'react-icons/fi';
import useApi from '../services/api';

const whiteColor = "#FFFFFF";
const blackColor = "#000000";
const grayColor = "#e5e5e5";

const PopupBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupContent = styled.div`
  background-color: ${whiteColor};
  color: ${blackColor};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 90%;
  max-height: 700px;
  height: 90%;
  text-align: right;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const LogoPlaceholder = styled.div`
  width: 100px;
  height: 50px;
  background-color: #ccc;
  margin-bottom: 20px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: ${blackColor};
`;

const Title = styled.h3`
  margin-top: 20px;
  display: block;
`;

const Section = styled.div`
  padding: 10px;
  margin-top: 20px;
  text-align: left;
`;

const EditIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CredentialInputContainer = styled.div`
  margin-bottom: 10px;
`;

const CredentialLabel = styled.p`
  margin-bottom: 5px;
  margin-right: 10px;
`;

const CredentialValue = styled.p`
  margin-bottom: 5px;
`;

const CredentialInput = styled.input`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid ${grayColor};
  display: block;
  margin-top: 5px;
`;

const EditIcon = styled(FiEdit)`
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SaveButton = styled.button`
  background-color: #333;
  color: ${whiteColor};
  padding: 10px 15px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const CancelButton = styled.button`
  background-color: ${whiteColor};
  color: ${blackColor};
  margin-right: 10px;
  padding: 10px 15px;
  border: 1px solid ${grayColor};
  border-radius: 4px;
  cursor: pointer;
`;

const LoaderContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(255, 255, 255, 0.7);
z-index: 1001;
`;

const Loader = styled.div`
border: 4px solid rgba(0, 0, 0, 0.1);
border-top: 4px solid #333; 
border-radius: 50%;
width: 50px;
height: 50px;
animation: spin 1s linear infinite; 

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`;

const LoaderText = styled.p`
  margin-top: 10px;
  font-size: 16px;
`;

const CredentialPopup = ({ isOpen, onClose, credential, tenant, schema, handleCredentialSuccess }) => {
  const [editable, setEditable] = useState(false);
  const [editedCredential, setEditedCredential] = useState(null);
  const [loading, setLoading] = useState(false);
  const { putProvidersAccounts } = useApi();

  useEffect(() => {
    setEditedCredential(credential);
  }, [credential]);

  useEffect(() => {
    if (isOpen) {
      setEditedCredential(credential);
    }
  }, [isOpen, credential]);

  const handleEdit = () => {
    setEditable(true);
    setEditedCredential(credential); 
  };

  const handleCancelEdit = () => {
    setEditable(false);
    setEditedCredential(credential);
  };

  const handleSaveEdit = async () => {
    setEditable(false);
    setLoading(true);

    const updatedCredential = {
      ...credential,
      ...editedCredential
    };

    const data = {
      tenantId: tenant.id,
      tenantName: tenant.name,
      schemaList: [
        {
          schemas: schema,
          credential: updatedCredential
        }
      ]
    };

    try {
      const res = await putProvidersAccounts(data);
      if (res) {
        handleCredentialSuccess(res);
        onClose(); 
      }
    } catch (error) {
      console.error("Error while saving credential:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCredentialChange = (key, value) => {
    setEditedCredential({
      ...editedCredential,
      [key]: value
    });
  };

  return (
    <>
      {isOpen && (
        <PopupBackground>
          <PopupContent>
            <CloseButton onClick={onClose}>×</CloseButton>
            <Header>
              <LogoPlaceholder />
              <Title>Account Credentials</Title>
            </Header>
            <Section>
              <EditIconContainer>
                {!editable && (
                  <EditIcon onClick={handleEdit} />
                )}
              </EditIconContainer>
              {Object.keys(credential).map((key, index) => (
                <CredentialInputContainer key={index}>
                  <CredentialLabel>{key}</CredentialLabel>
                  {editable ? (
                    <CredentialInput
                      type="text"
                      value={editedCredential[key]}
                      onChange={(e) =>
                        handleCredentialChange(key, e.target.value)
                      }
                    />
                  ) : (
                    <CredentialValue>{credential[key]}</CredentialValue>
                  )}
                </CredentialInputContainer>
              ))}
            </Section>
            {editable && (
              <ButtonContainer>
                <CancelButton onClick={handleCancelEdit}>Cancel</CancelButton>
                <SaveButton onClick={handleSaveEdit}>Save</SaveButton>
              </ButtonContainer>
            )}
          </PopupContent>
        </PopupBackground>
      )}
      {loading && (
        <LoaderContainer>
        <Loader />
        <LoaderText>Updating credential ...</LoaderText>
      </LoaderContainer>
      )}
    </>
  );
};

export default CredentialPopup;


//ToDo:
// -Adapt CredentialPopUp to the long values in amazon credentials
// -Posibe refactor for Credential props to know if should be updatable or not