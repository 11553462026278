


import { useNavigate } from "react-router-dom";

const Home = () => {
  return (
    <>
      <h1>Welcome to Numetri Prism App!</h1>
    </>
  );
};

export default Home