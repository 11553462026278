
function Dashboard() {
    return (
      <>
              <h1>Dashboard</h1>
              <p>
                  This is the Dashboard page.
              </p>
          </>
    )
  }
  
  export default Dashboard