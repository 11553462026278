import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import useApi from '../services/api';
import { FiSearch, FiDownload } from 'react-icons/fi';
import UploadPopup from './UploadPopup';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const UploadBackground = styled.div`
  position: fixed;
  top: 0;
  left: 200px;
  width: calc(100% - 200px);
  height: 100%;
  background-color: #e5e5e5;
  padding: 20px;
  overflow-y: auto;
`;

const UploadContainer = styled.div`
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 50px;
  margin: 50px 20px;
  position: relative;
`;

const UploadButton = styled.button`
  background-color: #333;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const Upload = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [reports, setReports] = useState(null);
  const [reportTypes, setReportTypes] = useState(null);
  const { getTenantReports, getTenantReportTypes,getTenantReport } = useApi();

  useEffect(() => {
    const fetchData = async () => {
      const reportsObtained = await getTenantReports();
      const reportTypesObtained = await getTenantReportTypes();
      setReports(reportsObtained);
      setReportTypes(reportTypesObtained);
    };
    fetchData();
  }, []);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleUploadSuccess = async () => {
    const reportsObtained = await getTenantReports();
    setReports(reportsObtained);
  }

  const handleDownloadFile = async (row) => {
    let path = row.original.blobStoragePath;
    let userFileName = row.original.userFileName;
    try {
      const reportToDownload = await getTenantReport(path);
      console.log(reportToDownload);
     
      if (reportToDownload) {
       
        const blob = new Blob([reportToDownload], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = userFileName; 
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        console.error("The server did not return the file correctly");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  }

  const columns = useMemo(
    () => [
      {
        header: 'User File Name',
        accessorKey: 'userFileName',
        filterVariant: 'multi-select',
        filterSelectOptions: reportTypes
          ? Array.from(new Set(reportTypes.map((report) => report.user_file_name)))
          : [],
      },
      {
        header: 'File Name',
        accessorKey: 'fileName',
        filterVariant: 'multi-select',
        filterSelectOptions: reportTypes
          ? Array.from(new Set(reportTypes.map((report) => report.file_name)))
          : [],
      },
      {
        header: 'File Type',
        accessorKey: 'jobName',
        filterVariant: 'multi-select',
        filterSelectOptions: reportTypes
          ? Array.from(new Set(reportTypes.map((report) => report.job_key)))
          : [],
      },
      {
        accessorFn: (originalRow) => new Date(originalRow.lastUploaded),
        id: 'lastUploaded',
        header: 'Last Uploaded',
        enableColumnFilter: false,
        Cell: ({ cell }) =>
          `${cell.getValue().toLocaleDateString()} ${cell
            .getValue()
            .toLocaleTimeString()}`,
      },
      {
        header: 'Status',
        accessorKey: 'status',
        enableColumnFilter: false,
      },
      {
        header: '',
        accessorKey: 'blobStoragePath',
        Cell: ({ row }) => ( 
          <FiDownload onClick={() => handleDownloadFile(row)} />
        ),
      },
    ],
    [reportTypes]
  );

  const table = useMaterialReactTable({
    columns,
    data: reports || [],
    initialState: { showColumnFilters: true },
    columnFilterDisplayMode: 'popover'
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <UploadBackground>
        <h2>Upload</h2>
        <UploadContainer>
          <UploadButton onClick={togglePopup}>+ Upload New</UploadButton>
          <MaterialReactTable table={table} />
        </UploadContainer>
        <UploadPopup isOpen={isOpen} onClose={togglePopup} reportTypes={reportTypes} handleUploadSuccess={handleUploadSuccess} />
      </UploadBackground>
    </LocalizationProvider>
  );
};

export default Upload;