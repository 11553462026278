import { Route, Routes } from "react-router-dom";
import { KindeProvider } from "@kinde-oss/kinde-auth-react";

import Navigation from "./components/Navigation";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import Integration from "./components/Integration";
import Upload from "./components/Upload";
import { AppProvider } from './context/AppContext';
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Home from "./components/Home"

const MainContainer = styled.div`
  margin-left: 200px; 
`;

function App() {
  const navigate = useNavigate();
  return (
    <>
    
      <KindeProvider
        clientId="425c3983f6e74ca18affa24f2fcfb867"
        domain="https://numetri-numetridev.us.kinde.com"
        audience="https://prism-api"
        logoutUri={window.location.origin}
        redirectUri={window.location.origin}
        onRedirectCallback={(user, app_state) => {
          navigate(`/`);
        }}
      >
      <AppProvider>
        <Navigation />
        <MainContainer>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<PrivateRoute Component={Dashboard} />} />
          <Route path="/integration" element={<PrivateRoute Component={Integration} />} />
          <Route path="/upload" element={<PrivateRoute Component={Upload} />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<p>!404¡Page not Found</p>} />
        </Routes>
        </MainContainer>
        </AppProvider>
      </KindeProvider>
      
    </>
  );
}

export default App;
