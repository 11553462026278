import axios from "axios";
import { useAppContext } from "../context/AppContext";

const useApi = () => {
  const { token, tenant } = useAppContext();

  const baseUrl = process.env.NODE_ENV ==='production'
  ? 'https://webapiprism.azurewebsites.net'
  : 'https://localhost:7076'

  const getTenantInfo = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/portal/user/gettenant`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token.accessToken}`,
          },
          params: {
            orgId: token.orgId,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching tenants data:", error);
      return null; 
    }
  };
  const getTenantAccounts = async () => {
    try {
      const response = await axios.get(
        `https://${tenant.domain}/portal/user/getprovidersaccounts`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token.accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching tenants data:", error);
      return null;
    }
  };
  const getTenantReports = async () => {
    try {
      const response = await axios.get(
        `https://${tenant.domain}/portal/user/getreports`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token.accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching tenants data:", error);
      return null;
    }
  };
  const getTenantReportTypes = async () => {
    try {
      const response = await axios.get(
        `https://${tenant.domain}/portal/user/getreporttypes`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token.accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching tenants data:", error);
      return null;
    }
  };
  const postReportFile = async (reportFile) => {
    try {
      const response = await axios.post(
        `https://${tenant.domain}/portal/user/postreportfile`,
        reportFile,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token.accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error post report :', error);
      return null;
    }
  };
  const putProvidersAccounts = async (accountInfo) => {
    try {
      const response = await axios.put(
        `https://${tenant.domain}/portal/user/putprovidersaccounts`,
        accountInfo,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token.accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error put provider credential :', error);
      return null;
    }
  };
  const getTenantReport = async (path) => {
    try {
      const response = await axios.get(
        `https://${tenant.domain}/portal/user/getreport`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token.accessToken}`,
          },
          params: {
            path: path,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching tenant report :", error);
      return null;
    }
  };

  return {
    getTenantInfo,
    getTenantAccounts,
    getTenantReports,
    getTenantReportTypes,
    postReportFile,
    putProvidersAccounts,
    getTenantReport,
  };
};
export default useApi;