import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FiSearch, FiChevronDown, FiChevronUp, FiEdit2, FiShoppingCart } from 'react-icons/fi';
import useApi from '../services/api';
import CredentialPopup from './CredentialPopup';

const grayColor = "#e5e5e5";

const IntegrationBackground = styled.div`
  position: fixed;
  top: 0;
  left: 200px;
  width: calc(100% - 200px);
  height: 100%;
  background-color: ${grayColor};
  padding: 20px;
  overflow-y: auto;
`;

const IntegrationContainer = styled.div`
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 50px;
  margin: 50px 20px;
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid ${grayColor};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  padding: 10px;
  background-color: ${grayColor};
  text-align: left;
`;

const Td = styled.td`
  border: none;
  border-bottom: 1px solid ${grayColor};
  padding: 10px;
`;

const EditIcon = styled(FiEdit2)`
  margin-right: 5px;
`;

const ArrowIcon = styled(({ isOpen, ...props }) => (
  isOpen ? <FiChevronUp {...props} /> : <FiChevronDown {...props} />
))`
  margin-left: 5px;
`;

const InfoPanel = styled.div`
  background-color: ${grayColor};
  padding: 10px;
  border-radius: 4px;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
`;

const ProviderCell = styled.div`
  display: flex;
  align-items: center;
`;
const ProviderIcon = styled(FiShoppingCart)`
  margin: 0 5px;
`;

const StatusCell = styled.td`
  background-color: ${grayColor};
  font-weight: bold;
  border: none;
  padding: 10px;
  border-radius: 8px;
`;

const JobSection = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 8px; 
  margin: 10px 0; 
  padding: 10px; 

  > * {
    padding: 0 50px; 
  }
`;

const Integration = ({ domain }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [openInfoIds, setOpenInfoIds] = useState({});
  const [accountInfoGrouped, setAccountInfoGrouped] = useState(null);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [selectedCredential, setSelectedCredential] = useState(null);
  const [paramsToPopup,setParamsToPopUp] = useState(null);
  const { getTenantAccounts } = useApi();

  useEffect(() => {
    const fetchData = async () => {
      const res = await getTenantAccounts();
      setAccountInfoGrouped(res);
      const initialOpenInfoIds = {};
      setOpenInfoIds(initialOpenInfoIds);
    }

    fetchData();
  }, []);

  const openPopup = (schema) => {
    setSelectedCredential(schema.credential);
  
    const tenant = {
      id: accountInfoGrouped.tenantId,
      name: accountInfoGrouped.tenantName
    };
  
    setParamsToPopUp({ tenant, schema : schema.schemas });
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleArrowClick = (index) => {
    setOpenInfoIds(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const handleCredentialSuccessUpdate= (res) => {
      setAccountInfoGrouped(res);
      const initialOpenInfoIds = {};
      setOpenInfoIds(initialOpenInfoIds);
  };

  return (
    <IntegrationBackground>
      <h2>Integrations</h2>
      <IntegrationContainer>
        <SearchContainer>
          <SearchInput
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <FiSearch />
        </SearchContainer>
        <Table>
          <thead>
            <tr>
              <Th>Provider</Th>
              <Th>Status</Th>
              <Th></Th>
            </tr>
          </thead>
          <tbody>
            {accountInfoGrouped &&
              accountInfoGrouped.schemaList.map((schema, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <Td>
                      <ProviderCell>
                        <ArrowIcon
                          isOpen={openInfoIds[index]}
                          onClick={() => handleArrowClick(index)}
                        />
                        <ProviderIcon />
                        {schema.schemas} ({accountInfoGrouped.tenantName})
                      </ProviderCell>
                    </Td>
                    <Td>
                      <StatusCell>Pending to define</StatusCell>
                    </Td>
                    <Td>
                      <EditIcon onClick={() => openPopup(schema)} />
                    </Td>
                  </tr>
                  <tr>
                    <Td colSpan="3" style={{ padding: 0 }}>
                      <InfoPanel isOpen={openInfoIds[index]}>
                        {schema.jobList.map((job, jobIndex) => (
                          <JobSection key={jobIndex}>
                            <p>
                              {job.jobKey}
                            </p>
                            <p>
                              <strong>Last Run Date:</strong> {job.lastRun}
                            </p>
                            <p>
                              <strong>Frequency (seg):</strong> {job.frequency}
                            </p>
                          </JobSection>
                        ))}
                      </InfoPanel>
                    </Td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        </Table>
      </IntegrationContainer>
      <CredentialPopup
        isOpen={isPopupOpen}
        onClose={closePopup}
        credential={selectedCredential}
        tenant={paramsToPopup ? paramsToPopup.tenant : null}
        schema={paramsToPopup ? paramsToPopup.schema : null}
        handleCredentialSuccess = {handleCredentialSuccessUpdate}
      />
    </IntegrationBackground>
  );
};

export default Integration;
