import {useKindeAuth} from '@kinde-oss/kinde-auth-react';

function Login() {
  const { login, register } = useKindeAuth();

  return (
    <div>
        <button onClick={register} type="button">Sign up</button>
        <button onClick={login} type="button">Log In</button>
    </div>
  )
}

export default Login