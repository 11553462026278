import React from 'react';
import { FaHome, FaFolder, FaCloud, FaFileUpload } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import TenantSwitcher from './TenantSwitcher';
import logo from '../assets/NumetriLogo.png';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  color: black;
  width: 200px;
  padding: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
`;

const Logo = styled.img`
  height: 60; 
  width: 100px; 
  margin-bottom: 20px;
`;

const Section = styled.div`
  margin-bottom: 20px;
  padding: 0 25px;
`;

const SectionLabel = styled.p`
  color: #888;
  font-size: 14px;
  margin-bottom: 5px;
`;

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &:hover {
    font-weight: bold;
  }
`;

const DashboardLink = styled(Link)`
  background-color: #f7f7f7;
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 8px 12px;
  border-radius: 5px;

  &:hover {
    font-weight: 600;
  }
`;

const Navigation = () => {

  const { logout, isAuthenticated } = useKindeAuth();
  return (
    <StyledNav>
    <Section>
    <StyledLink to="/">
          <Logo src={logo} alt="Numetri Logo" />
        </StyledLink>
        
      </Section>
    {isAuthenticated &&
    (
      <>
      <Section>
        <SectionLabel>Main</SectionLabel>
        <DashboardLink to="/dashboard">
          <FaHome className="nav-icon" />
          Dashboard
        </DashboardLink>
      </Section>
      <Section>
        <SectionLabel>Reports</SectionLabel>
        <StyledLink to="/">
          <FaFolder /> Report 1
        </StyledLink>
        <StyledLink to="/">
          <FaFolder /> Report 2
        </StyledLink>
        <StyledLink to="/">
          <FaFolder /> Report 3
        </StyledLink>
      </Section>
      <Section>
        <SectionLabel>Tools</SectionLabel>
        <StyledLink to="/integration">
          <FaCloud /> Integration
        </StyledLink>
        <StyledLink to="/upload">
          <FaFileUpload /> Import Files
        </StyledLink>
      </Section>
      </>
    ) }
      <Section>
        {isAuthenticated && <button onClick={logout}>Logout</button>}
        {!isAuthenticated && <StyledLink to="/login">Login</StyledLink>}
      </Section>
      <Section>
      {isAuthenticated && <TenantSwitcher />}
      </Section>
    </StyledNav>
  );
};

export default Navigation;
