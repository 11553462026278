import React, { useState } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { FiFilePlus, FiTrash2 } from 'react-icons/fi';
import useApi from '../services/api';

const whiteColor = "#FFFFFF";
const blackColor = "#000000";
const grayColor = "#e5e5e5";

const PopupBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; 
`;

const PopupContent = styled.div`
  background-color: ${whiteColor};
  color: ${blackColor};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 90%;
  max-height: 600px;
  height: 90%;
  text-align: right;
  z-index: 1000;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: ${blackColor};
`;

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: left;
  margin-bottom: 25px;
  padding-left: 10px;
`;

const Title = styled.h3`
  position: relative;
  z-index: 2;
`;

const Section = styled.div`
  padding: 10px;
  margin-top: 20px;
  text-align: left;
`;

const InputContainer = styled.div`
  margin-bottom: 10px;
`;

const InputLabel = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const SelectField = styled.select`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid ${grayColor};
  margin-bottom: 10px;
`;

const DropzoneContainer = styled.div`
  border: 2px dashed ${grayColor};
  padding: 10px;
  margin: 25px 0;
  text-align: center;
  cursor: pointer;
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: ${(props) => (props.file ? 'none' : 'auto')}; 
  opacity: ${(props) => (props.file ? '0.5' : '1')};
  position: relative;
`;

const DropzoneText = styled.p`
  margin-top: 20px;
`;

const DropzoneSubtitle = styled.p`
  margin: 5px 0 0 0;
  font-size: 12px;
`;

const ButtonContainer = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const UploadButton = styled.button`
  width: 50%;
  background-color: #333;
  color: ${whiteColor};
  padding: 10px 15px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const CancelButton = styled.button`
  width: 50%;
  background-color: ${whiteColor};
  color: ${blackColor};
  margin-right: 10px;
  padding: 10px 15px;
  border: 1px solid ${grayColor};
  border-radius: 4px;
  cursor: pointer;
`;

const TrashIcon = styled(FiTrash2)`
  cursor: pointer;
  color: red; 
  font-size: 20px; 
`;

const UploadFileIndicator = styled.div`
  margin-top: 10px;
  font-size: 14px;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1001;
`;

const Loader = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #333; 
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; 

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const LoaderText = styled.p`
  margin-top: 10px;
  font-size: 16px;
`;

const UploadPopup = ({ isOpen, onClose, reportTypes, handleUploadSuccess }) => {
  const [fileType, setFileType] = useState('');
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false); 
  const api = useApi();

  const onDrop = (acceptedFiles) => {
    setFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.csv',
    onDrop: onDrop
  });

  const handleFileTypeChange = (event) => {
    setFileType(event.target.value);
  };

  const handleUpload = async () => {
    if (!fileType || !file) {
      console.error('Please fill in all fields');
      return;
    }

    setLoading(true); 

    const formData = new FormData();
    formData.append('fileType', fileType);
    formData.append('file', file);

    try {
      const response = await api.postReportFile(formData);
      if (response) {
        console.log('File uploaded successfully');
        onClose();
        handleUploadSuccess();
        setFile(null);
        setFileType('');
      } else {
        console.error('Failed to upload file');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setLoading(false); 
    }
  };

  const handleDeleteFile = () => {
    setFile(null);
  };

  return (
    <>
      {isOpen && (
        <PopupBackground>
          <PopupContent>
            <CloseButton onClick={onClose}>×</CloseButton>
            <Header>
              <Title>Upload Files</Title>
            </Header>
            <Section>
              <InputContainer>
                <InputLabel>File Type</InputLabel>
                <SelectField
                  value={fileType}
                  onChange={handleFileTypeChange}
                >
                  <option value="">Select file type</option>
                  {reportTypes &&
                    reportTypes.map((type, index) => (
                      <option key={index} value={type.job_key}>
                        {type.file_name}
                      </option>
                    ))}
                </SelectField>
              </InputContainer>
              <DropzoneContainer {...getRootProps()} file={file}>
                <input {...getInputProps()} />
                <FiFilePlus size={80} color={grayColor} />
                <DropzoneText>Drag & Drop or choose file to upload</DropzoneText>
                <DropzoneSubtitle>10mb max size</DropzoneSubtitle>
                {file && (
                  <UploadFileIndicator>
                    File uploaded: {file.name}
                  </UploadFileIndicator>
                )}
              </DropzoneContainer>
              <InputContainer>
                {file && (
                  <TrashIcon onClick={handleDeleteFile} />
                )}
              </InputContainer>
            </Section>
            <ButtonContainer>
              <CancelButton onClick={onClose}>Cancel</CancelButton>
              <UploadButton onClick={handleUpload}>Upload</UploadButton>
            </ButtonContainer>
            {loading && (
              <LoaderContainer>
                <Loader />
                <LoaderText>Uploading...</LoaderText>
              </LoaderContainer>
            )}
          </PopupContent>
        </PopupBackground>
      )}
    </>
  );
};

export default UploadPopup;
