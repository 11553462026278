import { Navigate } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

const PrivateRoute = ({ Component }) => {
 
    const { isAuthenticated } = useKindeAuth();

 
  return isAuthenticated ? <Component /> : <Navigate to="/login" />;
};
export default PrivateRoute;